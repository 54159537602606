<template>
  <a-row class="content-fix" type="flex">
    <a-col class="height100 flex-1">
      <a-row class="btn-row">
        <button
          class="tab-btn"
          :class="{ active: tab === 'content' }"
          @click="tab = 'content'"
        >
          <a-icon type="project" />Содержимое
        </button>

        <button
          class="tab-btn"
          :class="{ active: tab === 'photo' }"
          @click="tab = 'photo'"
        >
          <a-icon type="picture" />Фото
        </button>
      </a-row>
      <div v-show="tab === 'content'" class="main-tabs" type="card">
        <!-- lang tabs -->
        <a-row align="middle" class="flex-baseline sm-mb">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            Язык:
          </a-col>
          <a-col :span="12">
            <!-- lang translit tabs -->
            <a-row align="middle" class="flex-baseline flex-wrap">
              <lang-tabs v-model="langTab" class="mb-1x" />
            </a-row>
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            Организация:
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input
              v-model="form['organization_name_' + getKey]"
              placeholder="Введите организация"
            />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            Адрес:
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input
              v-model="form['address_' + getKey]"
              placeholder="Введите aдрес"
            />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            Краткое содержание:
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input
              v-model="form['short_content_' + getKey]"
              placeholder="Введите краткое содержание"
            />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            Hомер телефона:
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input
              v-model="form.phone_number"
              v-mask="'+998 (##) ###-##-##'"
              placeholder="Введите номер телефона"
            />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            Email:
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input
              v-model="form.email"
              placeholder="Введите номер телефона"
              type="email"
            />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            Order:
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input v-model="form.order" type="number" placeholder="Order" />
          </a-col>
        </a-row>
        <a-row style="margin-top: 25px">
          <a-col :span="6" style="text-align: right; padding-right: 8px">
            Статус:
          </a-col>
          <a-switch v-model="form.is_active" />
        </a-row>
        <a-row align="middle" style="margin-top: 25px" type="flex">
          <a-col :span="6" style="padding: 4px 8px 15px; text-align: right">
            Cодержание :
          </a-col>
          <a-col v-show="langTab === 'oz'" :span="23" style="margin: 0 auto">
            <!-- :key="langtab" -->
            <editor
              :value="form.content_oz"
              :initial-value="form.content_oz"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content_oz = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>
          <a-col v-show="langTab === 'uz'" :span="23" style="margin: 0 auto">
            <!-- :key="langtab" -->
            <editor
              :value="form.content_uz"
              :initial-value="form.content_uz"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content_uz = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>
          <a-col v-show="langTab === 'qr'" :span="23" style="margin: 0 auto">
            <!-- :key="langtab" -->
            <editor
              :value="form.content_qr"
              :initial-value="form.content_qr"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content_qr = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>
          <a-col v-show="langTab === 'ru'" :span="23" style="margin: 0 auto">
            <!-- :key="langtab" -->
            <editor
              :value="form.content_ru"
              :initial-value="form.content_ru"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content_ru = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>
          <a-col v-show="langTab === 'en'" :span="23" style="margin: 0 auto">
            <!-- :key="langtab" -->
            <editor
              :value="form.content_en"
              :initial-value="form.content_en"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content_en = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-col :span="6" />
          <a-col :span="12">
            <a-row>
              <a-button style="margin-right: 10px" @click="$router.go(-1)">
                <a-icon type="close" />Отмена
              </a-button>
              <a-button type="primary" @click="submitData">
                <a-icon type="check" />Добавить
              </a-button>
            </a-row>
          </a-col>
        </a-row>
      </div>
      <!-- photo tab -->
      <div v-show="tab === 'photo'">
        <!-- upload photo -->
        <a-row align="middle">
          <a-col
            :span="6"
            style="padding-top: 4px; text-align: right; padding-right: 8px"
          >
            Изображения
          </a-col>
          <a-col :span="12">
            <image-upload
              :with-cropper="false"
              :buttons="true"
              :list="fileList"
              upload-multiple
              multiple
              has-method
              accept="image/jpeg, image/png"
              @change="handleImages"
              @set-main-image="activeImage"
              @activeImage="activeImage"
              @delete="deleteImage"
            />
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>

<script>
export default {
  components: {
    editor: () => import("@tinymce/tinymce-vue"),
    "image-upload": () => import("@/components/utils/image-upload")
  },
  data: () => {
    return {
      form: {
        organization_name_uz: "",
        organization_name_oz: "",
        organization_name_ru: "",
        organization_name_en: "",
        organization_name_qr: "",
        address_uz: "",
        address_ru: "",
        address_en: "",
        address_oz: "",
        address_qr: "",
        phone_number: "",
        content_ru: "",
        content_uz: "",
        content_oz: "",
        content_en: "",
        content_qr: "",
        type: 2,
        email: "",
        images: [],
        order: "",
        is_active: false
      },
      imageUploading: false,
      fileList: [],
      langTab: "oz",
      photo: null,
      pending: false,
      icon: "",
      tab: "content",
      newImage: null
    }
  },
  computed: {
    getKey() {
      return this.langTab
    },
    isContentNull() {
      return !!(
        !this.form.content_uz &&
        !this.form.content_oz &&
        !this.form.content_ru &&
        !this.form.content_qr &&
        !this.form.content_en
      )
    }
  },
  async created() {
    const form = await this.$store.dispatch(
      "organization/fetchOrganizationById",
      this.$route.params.id
    )
    this.form = form
    // this.icon = form.icon
    this.fileList = form.images
    // this.form.content = {
    //   ru: form.content.ru,
    //   en: form.content.en,
    //   oz: form.content.oz,
    //   uz: form.content.uz,
    //   qr: form.content.qr
    // }
  },

  methods: {
    async submitData() {
      this.pending = true
      const id = this.$route.params.id
      try {
        let form = this.form
        form = {
          ...form
        }
        delete form.slug
        delete form.thumb
        delete form.images
        if (form.email === null) {
          delete form.email
        }
        let f = new FormData()
        Object.keys(form).forEach((k) => {
          k !== "images" && f.append(k, form[k])
          // k === "images" &&
          //   form.images.forEach((k) => {
          //     f.append("images", k.fileTarget, k.fileTarget.name)
          //   })
        })
        await this.$api.patch(`/admin/structure/organization/${id}/update/`, f)
        this.$message.success("Цифровизация изменен успешно!")
        this.$router.push({ name: "organization" })
      } catch (error) {
        console.error(error)
        this.$sentry.captureMessage(error)
      }

      this.pending = false
    },
    async deleteImage(val) {
      await this.$api.delete(
        "/admin/structure/organization/image/" + val + "/delete/"
      )
    },
    activeImage(e) {
      // this.photoList.forEach((item, index) => {
      //   this.photoList[index].is_active = item.id === e
      // })
      let ind = this.fileList.findIndex((el) => el.id === e)
      this.fileList[ind].is_active = true
      this.$api.patch("/admin/structure/organization/image/" + e + "/update/")
    },
    // handleImages(arg) {
    //   this.form.images = arg
    //   console.log(this.images, arg)
    // },
    async handleImages(arg) {
      let formImage = {
        organization: this.$route.params.id
      }
      let newImages = arg.filter((item) => item && !item.id)
      let f = new FormData()
      Object.keys(formImage).forEach((k) => {
        f.append(k, formImage[k])
      })
      newImages.forEach((item) => {
        f.append(
          "files",
          item && item.fileTarget,
          item && item.fileTarget && item.fileTarget.name
        )
      })
      let res = await this.$api.post(
        "/admin/structure/organization/image/upload/",
        f
      )
      if (res && res.data) {
        this.form.images = res.data.images
        this.$router.go()
        console.log(this.form.images)
      }
    }
  }
}
</script>

<style>
.form_image > .org_image {
  width: 80px;
  height: 80px;
  border: 1px solid grey;
  border-radius: 50%;
  overflow: hidden;
  display: inline;
  margin-right: 20px;
}
.form_image > .org_image > img {
  width: 100%;
  height: 100%;
}
.form_image {
  margin: 10px 0;
  height: 70px;
  display: flex;
  align-items: center;
}
</style>
